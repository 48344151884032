<template>
  <div>
    <div class="about d-none d-sm-flex justify-center flex-column">
      <div class="banner">
        <div class="text-title white--text text-center">
          {{ $t("AppBar.logotext") }}
        </div>
        <v-card
          elevation="0"
          color="rgba(0, 0, 0, 0)"
          width="800"
          class="pa-6 mt-5 mx-auto"
        >
          <div class="text-h6 font-weight-regular white--text">
            {{ $t("Page.Fnd.Desc1") }}
          </div>
        </v-card>
      </div>
    </div>

    <div class="about d-block d-sm-none">
      <div class="banner text-left white--text px-6 pt-15 pb-5">
        <div class="text-title">{{ $t("AppBar.SubMenu.ScFnd") }}</div>
        <div class="text-body-2 mt-5">{{ $t("Page.Fnd.Desc1") }}</div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../../components/Footer.vue";

export default {
  components: {
    Footer,
  },
  mounted() {
  },
  watch: {
  },

  methods: {
  },
};
</script>

<style lang="scss">
.about {
  min-height: calc(100vh - 289px);
  padding:55px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.54), rgba(0, 0, 0, 0.54)),
    url("../../assets/images/foundation_bg.png") top right;
}
.banner {
  padding: 170px 120px 120px;

  .text-title {
    font-size: 64px;
    font-family: PingFang SC, PingFang SC-Semibold;
    font-weight: 600;
  }
}

@media screen and (max-width: 960px) {
   .banner {
            .text-title {
                font-size: 38px;
            }
        }
  .about {
    padding: 0;
}
}
</style>
